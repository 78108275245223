<template>
  <v-list
    expand
    nav
  >
    <!-- Style cascading bug  -->
    <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
    <div />

    <template v-for="(item, i) in computedItems">
      <left-navigation-menu-list-item
        :key="`item-${i}`"
        :item="item"
      />
    </template>

    <!-- Style cascading bug  -->
    <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
    <div />
  </v-list>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LeftNavigationMenuListItem from './LeftNavigationMenuListItem.vue'

  export default {
    name: 'LeftNavigationMenuList',
    components: { LeftNavigationMenuListItem },
    data () {
      return {
        items: [
          {
            icon: 'mdi-charity',
            title: 'agencies',
            to: '/',
          },
          {
            icon: 'mdi-account-multiple',
            title: 'caregivers',
            to: '/caregivers',
          },
          {
            icon: 'mdi-account-multiple',
            title: 'employees',
            to: '/employees',
            superAdmin: true,
          },
          {
            icon: 'mdi-account-multiple',
            title: 'internaladmins',
            to: '/iadmins',
          },
          {
            icon: 'mdi-chart-line',
            title: 'domo-reports',
            to: '/reports',
          },
          {
            icon: 'mdi-cog-outline',
            title: 'system-settings',
            to: '/settings',
            superAdmin: true,
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        isAdmin: 'app/IS_ADMIN',
      }),
      computedItems () {
        return this.items
          .map(e => ({
            ...e,
            title: this.$t(e.title),
          }))
          .filter(e => !e.superAdmin || (e.superAdmin && this.isAdmin))
      },
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
