<template>
  <v-img
    :src="logoCareConnect"
    max-height="30"
  />
</template>

<script>

  export default {
    name: 'LeftNavigationMenuHeaderImage',
    data () {
      return {
        logoCareConnect: require('@/assets/logo_careconnect.svg'),
      }
    },
  }
</script>
