<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
    permanent
  >
    <left-navigation-menu-header />
    <v-divider />
    <left-navigation-menu-list />
    <template v-slot:append>
      <left-navigation-menu-version />
    </template>
  </v-navigation-drawer>
</template>

<script>
  import { mapMutations } from 'vuex'
  import LeftNavigationMenuHeader from './LeftNavigationMenuHeader.vue'
  import LeftNavigationMenuList from './LeftNavigationMenuList.vue'
  import LeftNavigationMenuVersion from './LeftNavigationMenuVersion.vue'

  export default {
    name: 'LeftNavigationMenu',
    components: { LeftNavigationMenuHeader, LeftNavigationMenuList, LeftNavigationMenuVersion },
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
    },
    methods: {
      ...mapMutations({
        setDrawer: 'app/SET_DRAWER',
      }),
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
